import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/css/Navbar.css';
import logo from '../assets/images/gwtf-final stripped black.png'; // Import logo image
import { FaPhoneAlt, FaEnvelope, FaFileAlt } from 'react-icons/fa'; // Import icons

const Navbar = () => {
  return (
    <header className="navbar">
      {/* Top Section with Logo, Company Name, and Google Review */}
      <div className="top-section">
        <img src={logo} alt="Company Logo" className="company-logo" />
        <div className="company-name">
          <h1>Go with the Flo Plumbing</h1>
          <h1>and Home Maintenance</h1>
        </div>
        <div className="google-reviews-widget">
          <div className="elfsight-app-e2ef9bdd-dcca-4e9e-b615-d8c00814052c"></div>
        </div>
      </div>

      {/* Contact Information Section */}
      <div className="contact-info">
  <div className="contact-item left">
    <FaPhoneAlt className="contact-icon" />
    <a href="tel:2608375555">CONTACT US: 260-837-5555</a>
  </div>
  <div className="contact-item center">
    <FaEnvelope className="contact-icon" />
    <a href="mailto:gowiththeflo365@gmail.com">EMAIL: GOWITHTHEFLO365@GMAIL.COM</a>
  </div>
  <div className="contact-item right">
    <FaFileAlt className="contact-icon" />
    <a
      href="https://www.example.com/license"
      target="_blank"
      rel="noopener noreferrer"
    >
      LICENSE: PC#12400117
    </a>
  </div>
</div>


      {/* Navigation Menu */}
      <nav className="nav-menu">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/plumbing">Plumbing</Link>
          </li>
          <li>
            <Link to="/home-maintenance">Home Maintenance</Link>
          </li>
          <li>
            <Link to="/team">Meet the Crew</Link>
          </li>
          <li>
            <Link to="/gallery">Gallery</Link>
          </li>
          <li>
            <Link to="/booking">Book Now</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;
